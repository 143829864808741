import axios from "axios";
import Vue from 'vue';
import { SearchResult } from '@/models/SearchResult';

export default class CustomerProjectListService extends Vue {
    url = `${process.env.VUE_APP_PROJECT_URL}api/v1/projects`;

    get(keyword: string, page: number = 1, pageSize: number = 10): Promise<SearchResult<any>> {
        return axios.get(`${this.url}`,{
            params: {
                keyword: keyword,
                page: page,
                pageSize: pageSize
            }
        });
    }
}