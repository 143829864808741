










































import {Component, Vue, Ref, Watch, Prop} from "vue-property-decorator";
import CustomerProjectListService from "@/views/customer/project/CustomerProjectListService.ts";
import {SearchResult} from "@/models/SearchResult";
import SelectPageSize from "@/components/select-page-size/select-page-size.vue";
import debounce from 'lodash/debounce';

@Component({
    components: {
        SelectPageSize
    }
})
export default class CustomerProjectList extends Vue {
    @Ref("selectPageSize") selectPageSize!: any;
    @Prop()
    currentPage: number = 1;
    rows: any = [];
    totalPages = 0;
    pageSize = 10;
    list = {};
    totalRows = 0;
    keyword: string = "";
    debounce: any = null;

    customerProjectListService = new CustomerProjectListService();

    constructor() {
        super();
    }

    created() {
    }

    mounted() {
        this.search();
    }

    @Watch("keyword")
    onKeyWordInputChange(value: string, oldValue: string) {
        if (this.debounce) {
            this.debounce.cancel();
        }

        this.debounce = debounce(() => this.search(), 500);
        this.debounce();
    }

    @Watch("currentPage")
    onCurrentPageChange(value: string, oldValue: string) {
        this.search();
    }

    openLoading() {
        this.$vs.loading();
    }

    private async search() {
        this.openLoading();
        this.customerProjectListService
            .get(this.keyword, this.currentPage, this.pageSize)
            .then((result: SearchResult<any>) => {
                if (result.code > 0) {
                    this.rows = result.items;
                    this.setTotalPage(result.totalRows);
                }
            });
        setTimeout(() => {
            this.$vs.loading.close();
        }, 500);
    }

    private setTotalPage(totalRows: number) {
        this.totalRows = totalRows;

        if (totalRows == 0) {
            this.totalPages = 0;
        }
        if (totalRows <= this.pageSize) {
            this.totalPages = 1;
        }
        this.totalPages = Math.ceil(totalRows / this.pageSize);
    }

    beginSearch() {
        if (this.keyword == undefined || this.keyword.length < 2) {
            return;
        }

        this.search();
    }

    addNew() {

    }
}
